import classNames from "classnames";
import React from "react";
import { ReactNode } from "react";
import * as classes from "./Button.module.scss";

type TBaseButtonProps = {
  children: ReactNode;
  onClick: (() => void) | null;
  disabled?: boolean;
  disabledMessage?: string;
  className?: string;
};

const Button = ({
  children,
  onClick,
  disabled,
  disabledMessage,
  className,
}: TBaseButtonProps) => (
  <button
    className={classNames(classes["button"], className)}
    title={disabled ? disabledMessage : undefined}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);

Button.Primary = ({ children, className, ...props }: TBaseButtonProps) => (
  <Button {...props} className={classNames(classes["primary"], className)}>
    {children}
  </Button>
);

Button.Secondary = ({ children, className, ...props }: TBaseButtonProps) => (
  <Button {...props} className={classNames(classes["secondary"], className)}>
    {children}
  </Button>
);

export default Button;
