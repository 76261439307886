import { Link } from "gatsby";
import React from "react";
import URLs from "../data/URLs";
import Button from "./ui/Button";
import Icon from "./ui/Icon";
import * as classes from "./VerticalButtons.module.scss";

const VerticalButtons = ({ showText = true }: { showText?: boolean }) => (
  <div className={classes["verticalButtons"]}>
    <a href={URLs.fundraiser} target="_blank">
      <Button.Primary className={classes["heartButton"]} onClick={null}>
        <Icon name="heart" className={classes["heart"]} />
        Donate
      </Button.Primary>
    </a>

    <Link to={"/join"}>
      <Button.Secondary className={classes["joinButton"]} onClick={null}>
        Join The Fight
      </Button.Secondary>

      {showText && (
        <p className={classes["oswald"]}>Sign up for our email list</p>
      )}
    </Link>
  </div>
);

export default VerticalButtons;
