import React from "react";
import * as classes from "./VideoEmbed.module.scss";

const VideoEmbed = ({ embedId }: { embedId: string }) => (
  <div className={classes["videoResponsive"]}>
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

VideoEmbed.Vimeo = ({ videoId }: { videoId: string }) => (
  <div className={classes["videoResponsive"]}>
    <iframe
      src={`https://player.vimeo.com/video/${videoId}`}
      width="640"
      height="360"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    />
  </div>
);

VideoEmbed.MSNBC = ({ videoId }: { videoId: string }) => (
  <div className={classes["videoResponsive"]}>
    <iframe
      loading="lazy"
      width="560"
      height="315"
      src={`https://www.msnbc.com/msnbc/embedded-video/${videoId}`}
      scrolling="no"
      frameBorder="0"
      allowFullScreen={true}
    ></iframe>
  </div>
);
export default VideoEmbed;
