import React from "react";
import VideoEmbed from "../components/ui/VideoEmbed";
import * as classes from "./OkkerFinalRemarksSection.module.scss";

const OkkerFinalRemarksSection = () => (
  <>
    <h2 className={classes["okker"]}>Dr. Okker’s closing remarks:</h2>

    <div className={classes["videoContainer"]}>
      <VideoEmbed embedId="pyMBdYIpiDg" />
    </div>

    <p>
      On January 31st, the New College of Florida Board of Trustees voted to
      terminate <strong>President Patricia Okker</strong> and appoint a new
      interim president, former Florida Education Commissioner Richard Corcoran.
      Here are Dr. Okker’s closing remarks from the January 31st Board of
      Trustees meeting, including a heartfelt message to the current students to{" "}
      <strong>
        “know that you are truly loved here… not just for what you do, but for
        who you are”
      </strong>
      .
    </p>
  </>
);

export default OkkerFinalRemarksSection;
