import React from "react";
import VideoEmbed from "./ui/VideoEmbed";
import LinkedText from "./ui/LinkedText";
import URLs from "../data/URLs";

const VIDEO_ID = "836258513?h=5b48463e28";

const description = (
  <>
    <p>
      Freedom to choose course topics and materials, engage with relevant
      theories, and contribute new knowledge are central to academic life. This
      freedom is under serious assault in Florida, where censorious legislation
      aims to eclipse the academy’s ability to provide open, well-lit spaces for
      learning about issues that threaten our collective future as a democracy
      and impede innovations.
    </p>

    <p>
      Featuring Andrew Gothard, President of the United Faculty of Florida
      (UFF), and Jeremy Young from PEN America, and presented by the New College
      Chapter of UFF with additional support from the{" "}
      <LinkedText external={true} to={URLs.goFundMe}>
        Save New College GoFundMe
      </LinkedText>
      , this teach-in brought together scholars from across the Florida to take
      stock of our scholarly rights and responsibilities as we move forward in
      an increasingly uncertain era.
    </p>
  </>
);

const AcademicFreedomVideo = () => <VideoEmbed.Vimeo videoId={VIDEO_ID} />;

AcademicFreedomVideo.Description = description;
export default AcademicFreedomVideo;
