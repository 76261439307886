import React from "react";

export const Divider = () => (
  <hr
    style={{
      margin: "3rem 0",
      backgroundColor: "rgba(255,255,255,0.3)",
      height: "1px",
      border: 0,
    }}
  />
);
