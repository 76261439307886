import * as React from "react";
import { graphql, Link, PageProps } from "gatsby";
import * as classes from "./index.module.scss";
import Layout from "../components/ui/Layout";
import Button from "../components/ui/Button";
import URLs from "../data/URLs";
import GAScripts from "../components/GAScripts";
import LinkedText from "../components/ui/LinkedText";
import Icon from "../components/ui/Icon";
import classNames from "classnames";
import VideoEmbed from "../components/ui/VideoEmbed";
import Column from "../components/ui/Column";
import VerticalButtons from "../components/VerticalButtons";
import OkkerFinalRemarksSection from "../content/OkkerFinalRemarksSection";
import AcademicFreedomVideo from "../components/AcademicFreedomVideo";
import { Divider } from "../components/ui/Divider";

const IndexPage = ({ data }: PageProps<Queries.IndexPageQuery>) => {
  if (data.siteBuildMetadata?.buildTime) {
    console.log(
      `Built ${new Date(data.siteBuildMetadata.buildTime).toLocaleString()}.`
    );
  }

  return (
    <Layout className={classes["app"]}>
      <section className={classes["hero"]}>
        <div className={classes["heroButtons"]}>
          <Link to={"/join"}>
            <Button.Secondary className={classes["joinButton"]} onClick={null}>
              Join The Fight
            </Button.Secondary>
          </Link>
          <div className={classNames(classes["quote"], classes["quote1"])}>
            “Education is not preparation for life; education is life itself.”
            <br />— John Dewey
          </div>
          <a href={URLs.fundraiser} target="_blank">
            <Button.Primary onClick={null} className={classes["heartButton"]}>
              <Icon name="heart" className={classes["heart"]} />
              Donate
            </Button.Primary>
          </a>
        </div>

        <div className={classNames(classes["quote"], classes["quote2"])}>
          “Education is not preparation for life; education is life itself.”
          <br />— John Dewey
        </div>
      </section>

      <section className={classNames(classes["intro"], classes["section"])}>
        <Column>
          <h2>Defend Educational Freedom</h2>

          <p>
            Join the coalition to defend New College and educational freedom
            from a hostile political takeover.
          </p>
          <p>
            New College ranks fifth in the nation among public liberal arts
            colleges. This attack isn’t about the quality of our education.
            DeSantis is attacking schools and universities across the state in
            an attempt to control our freedom of speech, our freedom to learn,
            and our freedom to thrive in our communities.
          </p>

          <div className={classes["vertButtons"]}>
            <VerticalButtons />
          </div>

          <p className={classes["petition"]}>
            Sign the petition to{" "}
            <LinkedText
              to="https://www.change.org/p/reject-desantis-hostile-takeover-of-new-college"
              external={true}
            >
              reject DeSantis' hostile takeover
            </LinkedText>{" "}
            of New College
          </p>
        </Column>
      </section>

      <section className={classNames(classes["section"], classes["media"])}>
        <Column>
          <div className={classes["videoContainer"]}>
            <AcademicFreedomVideo />
          </div>

          <div className={classes["freedomVideo"]}>
            {AcademicFreedomVideo.Description}
          </div>

          <Divider />

          <h2>Follow Alex Wagner’s coverage:</h2>

          <div className={classes["tweet"]}>
            <blockquote className="twitter-tweet">
              <p lang="en" dir="ltr" className={classes["center"]}>
                "Woke? You mean practicing basic empathy? Valuing people who are
                part of your community?" - Kayla Kisseadoo was among the bright,
                motivated New College students Alex Wagner was able to speak
                with in Florida recently.{" "}
                <a href="https://t.co/cKfIDQQufP">pic.twitter.com/cKfIDQQufP</a>
              </p>
              — Alex Wagner Tonight (@WagnerTonight){" "}
              <a href="https://twitter.com/WagnerTonight/status/1631550906222235649?ref_src=twsrc%5Etfw">
                March 3, 2023
              </a>
            </blockquote>
          </div>

          <div className={classes["videoContainer"]}>
            <VideoEmbed.MSNBC videoId="mmvo164382789960" />
          </div>

          <div className={classes["videoContainer"]}>
            <VideoEmbed.MSNBC videoId="mmvo164383813885" />
          </div>

          <Divider />

          <div className={classes["freedomVideo"]}>
            <OkkerFinalRemarksSection />
          </div>

          <Divider />

          <div className={classes["catalystLink"]}>
            Follow New College student publication{" "}
            <LinkedText external={true} to="https://ncfcatalyst.com/">
              The Catalyst
            </LinkedText>{" "}
            to stay informed on all breaking coverage.
          </div>
        </Column>
      </section>

      <section className={classNames(classes["section"], classes["action"])}>
        <Column>
          <div className={classes["freedomVideo"]}>
            <h2>Join the action to make real change!</h2>
            <p>
              Dear New College alumni, students, faculty, Florida State
              residents, family, friends, and concerned citizens, it’s time to
              call your elected officials!
            </p>
            <p>
              Right now we are focusing on Tallahassee's role in confirming the
              DeSantis nominated members of the Board of Trustees. These
              nominations must be confirmed by the Senate, and they have the
              opportunity to hold a hearing, ask hard questions, and ultimately
              elect to not confirm - but whether they avail themselves of this
              authority depends on <strong>us</strong>!
            </p>

            <p>
              Sign the petition to{" "}
              <LinkedText
                to="https://www.change.org/p/reject-desantis-hostile-takeover-of-new-college"
                external={true}
              >
                reject DeSantis' hostile takeover
              </LinkedText>{" "}
              of New College.
            </p>

            <p>
              Call your Senator! Find your representatives{" "}
              <LinkedText
                external={true}
                to="https://flsenate.gov/Senators/Find"
              >
                here
              </LinkedText>
              .
            </p>
          </div>
        </Column>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query IndexPage {
    siteBuildMetadata {
      buildTime
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export const Head = ({ data }: PageProps<Queries.IndexPageQuery>) => (
  <>
    <title>{data.site?.siteMetadata?.title} - About</title>
    <meta name="description" content="Save New College" />
    <script
      async
      src="https://platform.twitter.com/widgets.js"
      charSet="utf-8"
    ></script>
    <GAScripts />
  </>
);

export default IndexPage;
